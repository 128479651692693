export const IMAGES_CONSTANTS = {
  INITIAL: '0ba1dffc-9088-4c94-a19d-3160c313f900',
  NATIVE_APP_PROMO: {
    APPLE_STORE: 'fe96977e-5807-4214-e674-2ee01dc58f00',
    GOOGLE_PLAY: '0662338a-105b-4d72-2259-cad5f3fc9400',
    TEL_LEFT: '3ce61297-731f-42ef-d3ed-1f873a91c700',
    TEL_RIGHT: 'fa9ada44-1fb7-4ae7-8226-4c73544a5a00',
  },
  HOME_BANNER_CAROUSEL: {
    PROPERTY: '90a77dd0-f200-4085-4df9-df09da725600',
    CAR: 'f644450c-bb28-4dd9-96c1-81a302e71300',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
  },
  ABOUT_US_SERVICE: {
    PROPERTY: 'aab5c97d-54b8-4688-55af-e3cb2d746500',
    CAR: '6a8c9dbe-2167-487e-8dd4-44084d505900',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
    DESIGNER_LEFT: '270ff694-e530-4e37-14c5-018f07493a00',
    DESIGNER_RIGHT: 'd46480f5-4f91-4fed-920d-4fde343e5b00',
  },
};
