import moment from "moment";
import 'moment-timezone';

export const convertToISOWithUTC = (momentDate) => {
  if (!momentDate || !momentDate.isValid()) {
    return null;
  }

  return momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .tz('UTC', true)
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

export const startToEndDates = (start, end) => {
  const startMom = moment(start);
  const endMom = moment(end);

  const startDateValid = start ? startMom.isValid() : false;
  const endDateValid = end ? endMom.isValid() : false;

  if ((!start && !end) || (!startDateValid && !endDateValid)) {
    return null;
  }

  if (!startDateValid && endDateValid) {
    return `${endMom.format('D MMM')}.`;
  }

  if (!endDateValid && startDateValid) {
    return `${startMom.format('D MMM')}.`;
  }

  if (!start || !end) {
    const singleDate = moment(start || end);
    return `${singleDate.format('D MMM')}.`;
  }

  if (startMom.isSame(endMom, 'month')) {
    return `${startMom.format('D')} - ${endMom.format('D')} ${endMom.format('MMM')}.`;
  }

  return `${startMom.format('D MMM')}. - ${endMom.format('D MMM')}.`;
};