export const APP_CONFIG_CONSTANT = {
  LAYOUT_CLASS_MOVE: {
    NAME_HOME_BANNER_FOCUS: 'name-home-banner-focus',
  },
  APP_VERSION: null,
  LANGUAGE: {
    DEFAULT_: null,
    SUPPORTED_LANGUAGES: [],
  },
  LIST_QUANTITY_PAGE: {
    PROPERTY: 15,
    CAR: 15,
    TOUR: 15,
  },
  DATE: {
    FORMAT_URL: 'DD-MM-YYYY',
    FORMAT_SELECTOR: 'DD MMM YYYY',
  },
  GUESTS_BOOKING: {
    ADULTS: {
      MIN: 1,
      MAX: 10,
      DEFAULT: 1,
    },
    CHILDREN: {
      MIN: 0,
      MAX: 5,
      DEFAULT: 0,
    },
    INFANTS: {
      MIN: 0,
      MAX: 2,
      DEFAULT: 0,
    }
  },
  DETAILS_GALLERY: {
    MOBILE: {
      ZOOM: {
        MAX_RADIO: 3,
      },
      SLIDES_PER_VIEW: 5.3,
      SPACE_BETWEEN: 5,
    },
    DESKTOP: {
      ZOOM: {
        MAX_RADIO: 3,
      },
      SLIDES_PER_VIEW: 10,
    },
  }
};
