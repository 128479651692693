export const PAGE_ROUTES_CONSTANTS = {
  ABOUT: '/about',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ABOUT_US: '/about-us',
  // main
  NOT_FOUND: '/*',
  HOME: '/',
  STAYS: '/stays',
  CARS: '/cars',
  TOURS: '/events',
  PROPERTY_LIST: '/stays/search',
  TOUR_LIST: '/events/search',
  CAR_LIST: '/cars/search',
  PROPERTY_DETAILS: `/stays/:slug`,
  TOUR_DETAILS: `/events/:slug`,
  CAR_DETAILS: `/cars/:slug`,
  BOOKING_REVIEW_PROPERTY: '/stays/booking/:slug',
  BOOKING_REVIEW_CAR: '/car/booking/:slug',
  // auth
  BOOKING_DETAILS: '/booking-detail/:bookingId/:categoryId',
  AUTH: '/auth',
  PROFILE: '/profile/*',
  PROFILE_BOOKINGS: '/profile/bookings',
  PROFILE_NOTIFICATIONS: '/profile/notifications',
  PROFILE_CHAT: '/profile/chat',
  PROFILE_REVIEWS: '/profile/reviews',
  PROFILE_EDIT: '/profile/edit',
};

export const PAGE_LINKS_CONSTANT = {
  ABOUT: '/about',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ABOUT_US: '/about-us',
  // main
  HOME: '/',
  STAYS: '/stays',
  CARS: '/cars',
  TOURS: '/events',
  PROPERTY_LIST: '/stays/search',
  TOUR_LIST: '/events/search',
  CAR_LIST: '/cars/search',
  PROPERTY_DETAILS: `/stays`,
  TOUR_DETAILS: `/events`,
  CAR_DETAILS: `/cars`,
  BOOKING_REVIEW_PROPERTY: '/stays/booking',
  BOOKING_REVIEW_CAR: '/car/booking',
  // auth
  BOOKING_DETAILS: '/booking-detail',
  AUTH: '/auth',
  PROFILE: '/profile',
  PROFILE_BOOKINGS: '/profile/bookings',
  PROFILE_NOTIFICATIONS: '/profile/notifications',
  PROFILE_CHAT: '/profile/chat',
  PROFILE_REVIEWS: '/profile/reviews',
  PROFILE_EDIT: '/profile/edit',
};
